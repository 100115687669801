export const UC_SCRIPT_SRC =
  'https://app.usercentrics.eu/browser-ui/latest/loader.js';

export enum Service {
  FACEBOOK_PIXEL = 'Facebook Pixel',
  FULLSTORY = 'Fullstory',
  GOOGLE_ANALYTICS = 'Google Analytics',
  GOOGLE_MAPS = 'Google Maps',
  GOOGLE_TAG_MANAGER = 'Google Tag Manager',
  PINTEREST = 'Pinterest',
  SENTRY = 'Sentry',
  TRUSTPILOT = 'Trustpilot',
  YOUTUBE = 'YouTube Video',
}
