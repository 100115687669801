/* eslint-disable no-prototype-builtins */
import { UC_SCRIPT_SRC } from '@quno/patient-journey/src/constants/usercentrics';
import {
  PRODUCTION,
  Stage,
} from '@quno/patient-journey/src/constants/environment';
import * as Sentry from '@sentry/react';
import { useContext, useEffect } from 'react';
import { AppInitContext } from '@quno/patient-journey/src/context/AppInitContext';
import { CookieContext } from '@quno/patient-journey/src/context/CookieContext';
import { QUNOMEDICAL_BRAND_ID } from '@quno/patient-journey/src/constants/brand';
import type { BrandFragment } from '@quno/patient-journey/src/contentful/generated/graphql';

export type CookiesList = Record<string, boolean>;

export const isServiceAllowed = (
  cookies: CookiesList | null,
  serviceName: string,
): { serviceName: string; serviceStatus: boolean } => {
  const serviceStatus = !!cookies?.[serviceName];

  return { serviceName, serviceStatus };
};

type CookieNotificationProps = {
  brand?: BrandFragment | null;
  skipScriptLoad?: boolean;
};

const CookieNotification = ({
  brand,
  skipScriptLoad,
}: CookieNotificationProps): JSX.Element => {
  const { hasAppInit } = useContext(AppInitContext);
  const { cookies, setCookies } = useContext(CookieContext);

  useEffect(() => {
    if (hasAppInit) {
      const { usercentricsScriptId, usercentricsSettingsId } = brand || {};
      const script = document.createElement('script');

      // Create script
      if (!skipScriptLoad && usercentricsScriptId && usercentricsSettingsId) {
        script.src = UC_SCRIPT_SRC;
        script.async = true;
        script.id = usercentricsScriptId;
        script.dataset.settingsId = usercentricsSettingsId;

        if (!PRODUCTION) {
          script.dataset.disableTracking = 'true';
          script.dataset.version = 'preview';
        }

        document.body.appendChild(script);
      }

      addEventListener('consentUpdate', handlePrivacy);

      return () => {
        if (script && script.parentNode?.nodeName === 'BODY') {
          document.body.removeChild(script);
        }

        removeEventListener('consentUpdate', handlePrivacy);
      };
    }
  }, [hasAppInit]);

  useEffect(() => {
    if (!brand || brand?.sys.id !== QUNOMEDICAL_BRAND_ID) {
      return;
    }

    // if (isServiceAllowed(cookies, Service.SENTRY).serviceStatus) {
    Sentry.init({
      enabled: PRODUCTION,
      dsn: process.env.SENTRY_DSN,
      environment:
        process.env.NEXT_PUBLIC_VERCEL_ENV || process.env.STAGE || Stage.LOCAL,
      autoSessionTracking: false,
      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      tracesSampleRate: 0.5,
      // Called for message and error events
      beforeSend(event) {
        if (event.user) {
          delete event.user.email;
          delete event.user.ip_address;
          delete event.user.username;
        }

        return event;
      },
    });

    Sentry.setTag('brand', brand.name);
    // }
  }, [cookies]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handlePrivacy = (e: any): void => {
    const { detail } = e;
    setCookies(detail);
    if (
      e.detail &&
      e.detail.event == 'consent_status' &&
      window.gtag !== undefined
    ) {
      // Please replace the analytics service name here with the customized service
      // eslint-disable-next-line no-var
      var ucAnalyticsService = 'Google Analytics';
      // Please replace the ad service name here with the customized service
      // eslint-disable-next-line no-var
      var ucAdService = 'Google Ads Remarketing';

      if (
        e.detail.hasOwnProperty(ucAnalyticsService) &&
        e.detail.hasOwnProperty(ucAdService)
      ) {
        window.gtag('consent', 'update', {
          ad_storage: e.detail[ucAdService] ? 'granted' : 'denied',
          ad_user_data: e.detail[ucAdService] ? 'granted' : 'denied',
          ad_personalization: e.detail[ucAdService] ? 'granted' : 'denied',
          analytics_storage: e.detail[ucAnalyticsService]
            ? 'granted'
            : 'denied',
        });
      } else {
        if (e.detail.hasOwnProperty(ucAdService)) {
          window.gtag('consent', 'update', {
            ad_storage: e.detail[ucAdService] ? 'granted' : 'denied',
            ad_user_data: e.detail[ucAdService] ? 'granted' : 'denied',
            ad_personalization: e.detail[ucAdService] ? 'granted' : 'denied',
          });
        }

        if (e.detail.hasOwnProperty(ucAnalyticsService)) {
          window.gtag('consent', 'update', {
            analytics_storage: e.detail[ucAnalyticsService]
              ? 'granted'
              : 'denied',
          });
        }
      }
    }
  };

  return <></>;
};

export default CookieNotification;
