import React from 'react';
import type { CookiesList } from '@quno/patient-journey/src/components/shared/CookieNotification';

export type CookieContext = Record<string, boolean> | null;

type CookieContextType = {
  setCookies: (list: CookieContext | null) => void;
  cookies: CookiesList | null;
};

export const CookieContext = React.createContext<CookieContextType>({
  setCookies() {
    return;
  },
  cookies: null,
});
