import * as Sentry from '@sentry/react';
import config from '@quno/patient-journey/config';
import Cookie from 'js-cookie';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { ContentfulLivePreviewProvider } from '@contentful/live-preview/react';
import { PRODUCTION } from '@quno/patient-journey/src/constants/environment';
import { UC_SCRIPT_SRC } from '@quno/patient-journey/src/constants/usercentrics';
import { CookieContext } from '@quno/patient-journey/src/context/CookieContext';
import CookieNotification from '@quno/patient-journey/src/components/shared/CookieNotification';
import FullStory from '@quno/patient-journey/src/components/shared/FullStory';
import { AppInitContext } from '@quno/patient-journey/src/context/AppInitContext';
import { CurrencyContext } from '@quno/patient-journey/src/context/CurrencyContext';
import { FunnelContext } from '@quno/patient-journey/src/context/FunnelContext';
import 'intersection-observer';
import '@quno/patient-journey/src/scripts/polyfills';
import {
  storeUserCountry,
  storeUserJourney,
} from '@quno/patient-journey/src/services/tracking/userJourney';
import '../styles/main.scss';
import { BrandProvider } from '@quno/patient-journey/src/context/BrandContext';
import GoogleTagManager from '@quno/patient-journey/src/components/shared/GoogleTagManager';
import locales from '@quno/patient-journey/src/config/locales.json';
import type { Page } from '@quno/patient-journey/src/contentful/generated/graphql-patient-journey';
import type { BrandContextType } from '@quno/patient-journey/src/context/BrandContext';

type PageProps = {
  brand: BrandContextType | null;
  page: Page;
  isPreview: boolean;
};

type Props = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Component: (props: any) => JSX.Element;
  pageProps: PageProps;
};

const App = ({ Component, pageProps }: Props): JSX.Element => {
  const { asPath, locale } = useRouter();
  const { brand, isPreview } = pageProps;

  const [currency, setCurrency] = useState<string>('');
  const [cookies, setCookies] = useState<CookieContext>(null);

  const [hasAppInit, setHasAppInit] = useState(false);

  const [externalFunnelLoaded, setExternalFunnelLoaded] = useState(false);
  const [hasCookieScriptLoaded, setHasCookieScriptLoaded] = useState(false);

  const contentfulLocale = locales.find((lang) => lang.locale === locale);

  useEffect(() => {
    setCurrency(Cookie.get('qm_currency') || config.defaults.currency);
    storeUserJourney(asPath, document.location.href, document.referrer);
    void storeUserCountry();
  }, []);

  useEffect(() => {
    const { usercentricsScriptId, usercentricsSettingsId } = brand || {};

    const hasStoredCookieSettings =
      window.localStorage && window.localStorage.uc_user_interaction;

    if (
      hasStoredCookieSettings &&
      usercentricsScriptId &&
      usercentricsSettingsId
    ) {
      // Create script
      const script = document.createElement('script');
      script.src = UC_SCRIPT_SRC;
      script.async = true;
      script.id = usercentricsScriptId;
      script.dataset.settingsId = usercentricsSettingsId;

      if (!PRODUCTION) {
        script.dataset.disableTracking = 'true';
        script.dataset.version = 'preview';
      }

      document.head.appendChild(script);
      setHasCookieScriptLoaded(true);
    }

    window.addEventListener('mousemove', handleAppInit);
    window.addEventListener('touchstart', handleAppInit);
    window.addEventListener('scroll', handleAppInit, { passive: true });

    const timer = setTimeout(
      () => {
        if (!hasAppInit) {
          handleAppInit();

          // clear the old timer
          if (timer) {
            clearTimeout(timer);
          }
        }
      },
      hasStoredCookieSettings ? 0 : 6000,
    );

    return () => {
      window.removeEventListener('mousemove', handleAppInit);
      window.removeEventListener('touchstart', handleAppInit);
      window.removeEventListener('scroll', handleAppInit);
      clearTimeout(timer);
    };
  }, [brand]);

  const handleAppInit = (): void => {
    setHasAppInit(true);

    window.removeEventListener('mousemove', handleAppInit);
    window.removeEventListener('touchstart', handleAppInit);
    window.removeEventListener('scroll', handleAppInit);
  };

  const props = pageProps.page || pageProps;

  return (
    <Sentry.ErrorBoundary>
      <BrandProvider brand={brand}>
        <CurrencyContext.Provider value={{ currency, setCurrency }}>
          <AppInitContext.Provider value={{ hasAppInit, setHasAppInit }}>
            <CookieContext.Provider
              value={{
                setCookies,
                cookies,
              }}
            >
              <FunnelContext.Provider
                value={{
                  externalFunnelLoaded,
                  setExternalFunnelLoaded,
                }}
              >
                <FullStory brand={brand} />
                <ContentfulLivePreviewProvider
                  locale={contentfulLocale?.code || 'en-US'}
                  enableInspectorMode={isPreview}
                  enableLiveUpdates={isPreview}
                >
                  <Component {...props} />
                </ContentfulLivePreviewProvider>
                <CookieNotification
                  brand={brand}
                  skipScriptLoad={hasCookieScriptLoaded}
                />
                <GoogleTagManager brand={brand} />
              </FunnelContext.Provider>
            </CookieContext.Provider>
          </AppInitContext.Provider>
        </CurrencyContext.Provider>
      </BrandProvider>
    </Sentry.ErrorBoundary>
  );
};

export default App;
