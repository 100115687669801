import React from 'react';

export type FunnelContextType = {
  externalFunnelLoaded: boolean;
  setExternalFunnelLoaded: (externalFunnelLoaded: boolean) => void;
};

export const FunnelContext = React.createContext<FunnelContextType>({
  externalFunnelLoaded: false,
  setExternalFunnelLoaded: () => undefined,
});
