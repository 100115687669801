import Cookie from 'js-cookie';
import config from '@quno/patient-journey/config';
import { safeParseJSON } from '@quno/patient-journey/src/utils/json';
import { getCountry } from '@quno/patient-journey/src/utils/location';

const USER_JOURNEY_INFO_COOKIE_KEY = 'userJourneyInfo';

const USER_JOURNEY_COUNTRY_COOKIE_KEY = 'userJourneyCountry';

// -- Wed Jan 13 2038 19:20:55 GMT
const USER_JOURNEY_EXPIRE_DATE = new Date(2147023255552);

export interface UserJourneyInformation {
  entrypointReferrer: string;
  entrypointSlug: string;
  entrypointParams: string;
}

export interface UserJourneyCountry {
  country: string;
}

export function storeUserJourney(
  fullPath: string,
  href: string,
  referrer: string,
): void {
  const data = safeParseJSON(
    Cookie.get(USER_JOURNEY_INFO_COOKIE_KEY),
  ) as UserJourneyInformation | null;

  if (!data) {
    const [entrypointSlug, entrypointParams] = fullPath.split('?');

    Cookie.set(
      USER_JOURNEY_INFO_COOKIE_KEY,
      JSON.stringify({
        entrypointReferrer: referrer,
        entrypointHref: href,
        entrypointParams,
        entrypointSlug,
      }),
      {
        sameSite: 'lax',
        expires: USER_JOURNEY_EXPIRE_DATE,
      },
    );
  }
}

export async function storeUserCountry(useFallback = true): Promise<void> {
  const data = safeParseJSON(
    Cookie.get(USER_JOURNEY_COUNTRY_COOKIE_KEY),
  ) as UserJourneyInformation | null;

  if (!data) {
    await getCountry().then((country) => {
      if (
        useFallback &&
        !config.countries[country as keyof typeof config.countries]
      ) {
        Cookie.set(USER_JOURNEY_COUNTRY_COOKIE_KEY, config.defaults.country, {
          sameSite: 'lax',
          expires: USER_JOURNEY_EXPIRE_DATE,
        });
      } else {
        Cookie.set(USER_JOURNEY_COUNTRY_COOKIE_KEY, country, {
          sameSite: 'lax',
          expires: USER_JOURNEY_EXPIRE_DATE,
        });
      }
    });
  }
}
