import React from 'react';
import config from '@quno/patient-journey/config';

export type CurrencyContextType = {
  setCurrency: (currency: string) => void;
  currency: string;
};

export const CurrencyContext = React.createContext<CurrencyContextType>({
  setCurrency: () => '',
  currency: config.defaults.currency,
});
